















































import Vue from 'vue'
import { TRANSACTION_TYPE } from '@/utils/transactions/constants'
import moment from 'moment'
import { authorizedRequest } from '@/api/api'
import { API_ROUTES } from '@/api/apiRoutes'

export default Vue.extend({
    props: {
        value: {
            type: Boolean,
        },
        positionId: {
            type: Number,
        },
        currency: {
            type: Object,
        },
    },
    data() {
        return {
            transactionType: TRANSACTION_TYPE.BUY,
            amount: 0,
            price: 0,
            date: new Date(),
            time: moment().format('HH:mm'),
            note: '',

            transactionTypeOptions: [
                {
                    text: 'Buy',
                    value: TRANSACTION_TYPE.BUY
                },
                {
                    text: 'Sell',
                    value: TRANSACTION_TYPE.SELL
                },
            ],
        }
    },
    computed: {
        showModal: {
            get(): boolean {
                return this.value
            },
            set(value: boolean) {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        currency: {
            handler() {
                this.price = this.currency.price
            },
            deep: true,
        },
    },
    methods: {
        addTransaction() {
            return authorizedRequest().post(API_ROUTES.PORTFOLIOS.ADD_TRANSACTION, {
                position_id: this.positionId,
                currency_id: this.currency.id,
                type: this.transactionType,
                price: this.price,
                amount: this.amount,
                created_at: moment(this.date + ' ' + this.time).utc(),
                note: this.note,
            })
                .then(() => {
                    this.$emit('create')
                })
                .catch((e) => console.error(e))
        },
    },
    mounted() {
        this.price = this.currency?.price ?? 0
    },
})
