















































import Vue from 'vue'
import { TRANSACTION_TYPE } from '@/utils/transactions/constants'
import moment from 'moment'
import { authorizedRequest } from '@/api/api'
import { API_ROUTES } from '@/api/apiRoutes'

export default Vue.extend({
    props: {
        value: {
            type: Boolean,
        },
        transaction: {
            type: Object,
        },
    },
    data() {
        return {
            transactionType: TRANSACTION_TYPE.BUY,
            amount: 0,
            price: 0,
            date: new Date(),
            time: moment().format('HH:mm'),
            note: '',

            transactionTypeOptions: [
                {
                    text: 'Buy',
                    value: TRANSACTION_TYPE.BUY
                },
                {
                    text: 'Sell',
                    value: TRANSACTION_TYPE.SELL
                },
            ],
        }
    },
    computed: {
        showModal: {
            get(): boolean {
                return this.value
            },
            set(value: boolean) {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        transaction: {
            handler() {
                this.refreshData()
            },
            deep: true,
        },
    },
    methods: {
        refreshData(): void {
            this.transactionType = this.transaction.type
            this.amount = this.transaction.amount
            this.price = this.transaction.price
            this.date = moment(this.transaction.created_at).toDate()
            this.time = moment(this.transaction.price).format('HH:mm')
            this.note = this.transaction.note
        },
        updateTransaction() {
            return authorizedRequest().post(API_ROUTES.PORTFOLIOS.UPDATE_TRANSACTION + this.transaction.id, {
                type: this.transactionType,
                price: this.price,
                amount: this.amount,
                created_at: moment(this.date + ' ' + this.time).utc(),
                note: this.note,
            })
                .then(() => {
                    this.showModal = false
                    this.$emit('update')
                })
                .catch((e) => console.error(e))
        },
    },
    mounted() {
        this.refreshData()
    },
})
