




import Vue from 'vue'

export default Vue.extend({
    props: {
        currency: {
            type: Object,
            required: true,
        },
    },
    computed: {
        imageUrl(): string {
            return this.currency.image_url
        },
        title(): string {
            return this.currency.title
        },
    },
})
