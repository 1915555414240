import Vue from 'vue'
import format from 'format-number'

export default Vue.extend({
    methods: {
        formatUSDValue(value: number): string {
            return pickFormatter(value)(value)
        },
        formatValue(value: number): string {
            return pickFormatter(value)(value, { noUnits: true })
        },
    },
})

function pickFormatter(value: number) {
    return format({
        prefix: '$',
        decimalsSeparator: '',
        integerSeparator: ' ',
        decimal: '.',
        round: getDecimalPlaces(value),
    })
}

function getDecimalPlaces(value: number): number {
    value = Math.abs(value)
    if (value < 0.00000001) {
        return 20
    } else if (value < 0.001) {
        return 8
    } else if (value < 1) {
        return 4
    } else if (value > 10000) {
        return 0
    } else {
        return 2
    }
}
