

















































































import { authorizedRequest } from '@/api/api'
import { API_ROUTES } from '@/api/apiRoutes'
import AddTransactionButton from '@/components/addTransactionButton/AddTransactionButton.vue'
import UpdateTransactionButton from '@/components/updateTransactionButton/UpdateTransactionButton.vue'
import CurrencyImage from '@/components/currencyImage/CurrencyImage.vue'
import { TRANSACTION_TYPE } from '@/utils/transactions/constants'
import mixins from 'vue-typed-mixins'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import ShowPositionNotesButton from '@/components/positionNotes/ShowPositionNoteButton.vue'

export default mixins(numberFormatMixin).extend({
    components: {
        ShowPositionNotesButton,
        AddTransactionButton,
        UpdateTransactionButton,
        CurrencyImage,
    },
    data() {
        return {
            // todo: types
            position: {} as any,
            transactions: [],
            stats: {},

            fields: [
                {
                    key: 'type',
                    label: 'Type',
                },
                {
                    key: 'price',
                    label: 'Price',
                },
                {
                    key: 'amount',
                    label: 'Amount',
                },
                {
                    key: 'actions',
                    label: 'Actions',
                },
            ],
            TRANSACTION_TYPE: TRANSACTION_TYPE,
        }
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: 'Home',
                    to: { name: 'home' },
                },
                {
                    // @ts-ignore
                    text: this.position.portfolio?.title ?? '',
                    to: {
                        name: 'portfolios.single',
                        // @ts-ignore
                        params: { portfolioId: this.position.portfolio_id },
                    },
                },
                {
                    // @ts-ignore
                    text: this.position.currency?.title ?? '',
                    active: true,
                },
            ]
        },
    },
    methods: {
        loadPortfolio() {
            authorizedRequest().get(API_ROUTES.PORTFOLIOS.GET_SINGLE_POSITION + this.$route.params.positionId)
                .then((response) => {
                    this.position = response.data.position
                    this.transactions = response.data.position.transactions
                    this.stats = response.data.stats
                })
                .catch((e) => console.error(e))
        },
        removeTransaction(transaction: any) {
            authorizedRequest().delete(API_ROUTES.PORTFOLIOS.DELETE_TRANSACTION + transaction.id)
                .then((response) => {
                    return this.reload()
                })
                .catch((e) => console.error(e))
        },
        closePosition() {
            authorizedRequest().delete(API_ROUTES.PORTFOLIOS.CLOSE_POSITION + this.$route.params.positionId)
                .then((response) => {
                    return this.$router.push({
                        name: 'portfolios.single',
                        params: { portfolioId: this.position!.portfolio_id },
                    })
                })
                .catch((e) => console.error(e))
        },
        reload() {
            this.loadPortfolio()
        },
    },
    mounted() {
        this.loadPortfolio()
    },
})
