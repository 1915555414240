














import Vue from 'vue'
import AddTransactionModal from '@/components/addTransactionModal/AddTransactionModal.vue'

export default Vue.extend({
    components: {
        AddTransactionModal,
    },
    props: {
        position: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showAddNewTransactionModal: false,
        }
    },
    methods: {
        onCreate() {
            this.$emit('create')
        },
    },
})
