




































import Vue from 'vue'
import { API_ROUTES } from '@/api/apiRoutes'
import { authorizedRequest } from '@/api/api'

export default Vue.extend({
    props: {
        position: {
            type: Object,
        },
    },
    data() {
        return {
            showViewModal: false,
            showEditModal: false,

            editedNoteText: '',
        }
    },
    computed: {
        noteHtml(): string {
            if (!this.position.note) {
                return ''
            }
            return this.position.note.replaceAll('\n', '</br>')
        },
    },
    methods: {
        openEditDialog(): void {
            this.editedNoteText = this.position.note
            this.showEditModal = true
        },
        changeNote(): void {
            authorizedRequest().post(`${API_ROUTES.PORTFOLIOS.UPDATE_POSITION}${this.position.id}`, {
                note: this.editedNoteText,
            })
                .then((response) => {
                    this.position.note = response.data.note
                })
                .catch((e) => console.error(e))
        },
    },
})
