














import Vue from 'vue'
import UpdateTransactionModal from '@/components/updateTransactionButton/UpdateTransactionModal.vue'

export default Vue.extend({
    components: {
        UpdateTransactionModal,
    },
    props: {
        transaction: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showModal: false,
        }
    },
    methods: {
        onUpdate() {
            this.$emit('update')
        },
    },
})
